// const searchBar = {
//     init: function() {
//         const offcanvasSearchElement = $('#searchSidebar');
//
//         if (!offcanvasSearchElement.length) {
//             return;
//         }
//
//         const mediaQueryTwo = window.matchMedia('(max-width: 1199px)');
//
//         function handleOffcanvasPositionChange(e) {
//             if (e.matches) {
//                 offcanvasSearchElement.removeClass('offcanvas-end').addClass('offcanvas-start');
//             } else {
//                 offcanvasSearchElement.removeClass('offcanvas-start').addClass('offcanvas-end');
//             }
//         }
//
//         handleOffcanvasPositionChange(mediaQueryTwo);
//
//         mediaQueryTwo.addEventListener('change', handleOffcanvasPositionChange);
//
//         const searchInput = $('#example-search-input');
//         const clearIcon = $('.clear-icon');
//         const searchItems = $('.search-sidebar-item');
//
//         const searchToggles = $('.search-toggle');
//
//         searchInput.on('input', function() {
//             const searchTerm = searchInput.val().toLowerCase();
//
//             searchItems.each(function() {
//                 const item = $(this);
//                 const itemName = item.data('name');
//                 const link = item.find('.search-sidebar-link');
//
//                 link.html(link.text());
//
//                 if (itemName.includes(searchTerm)) {
//                     item.show();
//
//                     const regex = new RegExp(`(${searchTerm})`, 'gi');
//                     link.html(link.text().replace(regex, '<strong>$1</strong>'));
//                 } else {
//                     item.hide();
//                 }
//             });
//
//             if (searchTerm) {
//                 searchInput.addClass('not-empty');
//             } else {
//                 searchInput.removeClass('not-empty');
//             }
//         });
//
//         clearIcon.on('click', function() {
//             searchInput.val('').removeClass('not-empty');
//             searchItems.each(function() {
//                 const item = $(this);
//                 const link = item.find('.search-sidebar-link');
//                 link.html(link.text());
//                 item.show();
//             });
//         });
//
//         searchToggles.on('click', function() {
//             setTimeout(function() {
//                 searchInput.focus();
//             }, 500); // 500ms delay
//         });
//     }
// }
//
// $(document).ready(function() {
//     searchBar.init();
// });



document.addEventListener("DOMContentLoaded", function () {
    const searchInput = document.querySelector("#search-input");
    const resultsContainer = document.querySelector("#search-result");
    const searchToggles = $('.search-toggle');

    searchInput.addEventListener("input", function () {
        const query = searchInput.value.trim();

        if (query.length < 2) {
            resultsContainer.innerHTML = "";
            return;
        }

        fetch(`search.php?search-field=${encodeURIComponent(query)}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.text();
            })
            .then((html) => {
                resultsContainer.innerHTML = html;
            })
            .catch((error) => {
                console.error("Error fetching search results:", error);
                resultsContainer.innerHTML = "<div>Error loading results.</div>"; // Optional error message
            });
    });


    searchToggles.on('click', function() {
            setTimeout(function() {
                searchInput.focus();
            }, 500); // 500ms delay
    });
});

