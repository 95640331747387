$(document).ready(function () {
    $(document).on('click', '.like-product', function (event) {
        event.preventDefault();

        const $this = $(this);
        const productId = $this.data('like-product-id'); // Get product ID
        const isLiked = $this.hasClass('fa-solid'); // Determine if currently liked

        $(`.like-product[data-like-product-id="${productId}"]`).each(function () {
            $(this).toggleClass('fa-solid fa-regular');
        });

        const action = isLiked ? 'remove' : 'add';
        const message = isLiked ? 'Product unliked!' : 'Product liked!';
        const icon = isLiked ? 'fa-info-circle' : 'fa-check-circle';
        const notificationType = isLiked ? 'info' : 'success';
        showNotification(message, notificationType, icon);

        $.ajax({
            url: 'liked.php',
            type: 'GET',
            data: { [action]: true, product_id: productId },
            success: function (response) {
                $('.account-options-count').text(response.trim());
            }
        });
    });
});